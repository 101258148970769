'use client';

import {
  useEffect,
  useId,
  useMemo,
  useState,
  type FunctionComponent,
} from 'react';
import { usePathname } from 'next/navigation';
import { withErrorBoundary } from 'react-error-boundary';

import { type AdConfig } from '@/types/AdConfig';
import { type AdSize } from '@/types/AdSizes';
import { type AdTargeting } from '@/types/AdTargeting';

import { NoUiErrorFallback } from '@/components/ErrorFallback';

import useAdSlot from '../useAdSlot';

const ConfigurableSlot: FunctionComponent<{
  className?: string;
  size: AdSize[];
  targeting: AdTargeting;
  pageTargeting: AdConfig;
  sizeMapping?: googletag.SizeMappingArray;
}> = ({ className = '', pageTargeting, size, sizeMapping = [], targeting }) => {
  const uniqueId = useId();
  // Using a stable, memoized ID for each ad slot to prevent recreation on re-renders
  const id = useMemo(() => `ad-${uniqueId}`, [uniqueId]);
  const pathname = usePathname();
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [pathname]);

  useAdSlot({
    id,
    path: pageTargeting?.adPath || '',
    size,
    sizeMapping,
    targeting: { ...pageTargeting.adTargeting, ...targeting },
  });

  return <div className={className} data-ad id={id} key={key} />;
};

export default withErrorBoundary(ConfigurableSlot, {
  FallbackComponent: NoUiErrorFallback,
});
