import { DtcWidgetStrings as strings } from '@/translations';
import { Link, Typography } from '@motortrend/ids';

import { MOTORTREND_MARKETPLACE_URL } from '@/utils/dtcWidget/constants';
import { DataId } from '@/utils/nitrous/constants';

import MarketplaceLogo from '@/assets/logos/bg-marketplace.svg';

import { type DtcWidgetYMMHeaderProps } from './DtcWidgetYMMHeader.props';

const DtcWidgetHeaderLogo = () => (
  <Link
    className="mt-2 flex h-11 w-auto sm:my-2 sm:ml-2 sm:h-14 sm:w-40 sm:items-end lg:items-start"
    data-id={DataId.DtcWidgetHeaderLogo}
    data-parent={DataId.DtcWidgetListings}
    href={MOTORTREND_MARKETPLACE_URL}
    target="_blank"
  >
    <div className="flex h-[28px] w-min items-center justify-center sm:h-[50px]">
      <MarketplaceLogo
        className="h-full w-auto text-primary-2"
        title={strings.MarketplaceBannerAltText}
      />
    </div>
  </Link>
);

const DtcWidgetYMMHeader = ({ text }: DtcWidgetYMMHeaderProps) => (
  <>
    <div className="mx-4 flex items-center justify-between">
      <Typography as="h3" className="mb-1" md="h4" variant="h5">
        {text}
      </Typography>
      <DtcWidgetHeaderLogo />
    </div>
  </>
);

export default DtcWidgetYMMHeader;
