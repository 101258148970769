import { env } from '@/config';

export interface BENode {
  content?: string;
  date_created?: number;
  date_published?: number;
  engine_version?: string;
  feature_group?: string;
  publishing_engine?: string;
  type: string;
}

export interface RelatedLinks {
  links: { anchor_text: string; url: string; desc: string }[];
}

export interface BrightEdgeData {
  nodes?: BENode[];
  config?: { page_groups?: { include_rules: string[]; name: string }[] };
  page_group_nodes?: Record<string, BENode[]>;
}

export const getPageHash = (pageUrl: string): string => {
  let hash = 0;
  if (pageUrl === null || pageUrl.length === 0) {
    return hash.toString();
  }
  for (let i = 0; i < pageUrl.length; i++) {
    const charCode = pageUrl.charCodeAt(i);
    hash = (hash << 5) - hash + charCode;
    hash &= hash; // Convert to 32-bit integer
  }
  return hash < 0 ? `0${-hash}` : `${hash}`;
};

export const getBrightEdgeData = async (
  url: string,
): Promise<BrightEdgeData | null> => {
  if (!url) {
    return null;
  }
  try {
    const hashCode = getPageHash(url);
    const endpoint = `${env.brightEdge.url}/${hashCode}`;
    const data = await fetch(endpoint);
    const res = (await data.json()) as BrightEdgeData;
    return res;
  } catch (e) {
    console.error({ details: 'Error on BrightEdgeData get', err: e });
    return {};
  }
};

export const getContentByType = (
  brightEdgeData: BrightEdgeData,
  type: string,
) => {
  const node = brightEdgeData?.nodes?.find((item) => item.type === type);

  // Suppress for homepage any fallback logic
  if (node?.content || window.location.pathname === '/') {
    return node;
  }

  // Load defaults logic in case BrightEdge does not send curated links
  const section =
    brightEdgeData?.config?.['page_groups']?.find((item) => {
      const includeRules = item.include_rules;
      for (let i = 0; i < includeRules.length; i += 1) {
        const pattern = new RegExp(includeRules[i]);
        if (pattern.test(window.location.href)) {
          return true;
        }
      }
      return false;
    })?.name || 'Global Settings';

  const fallbackNode = brightEdgeData?.['page_group_nodes']?.[section]?.find(
    (item) => item.type === type,
  );
  if (fallbackNode?.content) {
    return fallbackNode;
  }

  // Fallback for lower environments to show editorial links
  // as BrightEdge is having content only for prod links
  const editorNode = brightEdgeData?.['page_group_nodes']?.[
    'Editorial Content'
  ]?.find((item) => item.type === type);
  return editorNode;
};
