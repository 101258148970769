'use client';

import {
  useCallback,
  useEffect,
  useState,
  type FunctionComponent,
} from 'react';
import useStore from '@/store';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton } from '@motortrend/ids';

import { type AdConfigProps } from '@/types/AdConfig';
import { type AdSizes } from '@/types/AdSizes';

import { DataId } from '@/utils/nitrous/constants';
import Ad from '@/components/Ad';

const sizes: AdSizes = {
  desktop: [[970, 90], [728, 90], 'fluid'],
  laptop: [[970, 90], [728, 90], 'fluid'],
  mobile: [[320, 50], 'fluid'],
  tablet: [[728, 90], 'fluid'],
};

const adTargeting = {
  fluid: 'bottom_adhesion',
  pos: ['bottom'],
};

const BottomAdhesionAd: FunctionComponent<AdConfigProps> = ({
  pageTargeting,
}) => {
  const [adVisible, setAdVisible] = useState(false);
  const [showAd, setShowAd] = useState(true);
  const { setStickyData } = useStore();

  const handleAdRender = useCallback((isEmpty: boolean) => {
    setShowAd(!isEmpty);
    setAdVisible(!isEmpty);
  }, []);

  useEffect(() => {
    setStickyData({ bottomAdhesion: showAd ? 90 : 0 });
  }, [setStickyData, showAd]);

  if (!showAd) {
    return;
  }

  return (
    <div
      className={
        adVisible
          ? 'sticky bottom-0 z-40 w-full overflow-hidden bg-neutral-7/60 backdrop-blur-sm transition-colors dark:bg-neutral-3/60'
          : 'sticky bottom-0 z-40'
      }
    >
      {adVisible && (
        <IconButton
          aria-label="Close Ad"
          className="absolute right-3 top-3 opacity-90"
          colorScheme="neutral-8"
          data-id={DataId.CloseBottomAdButton}
          data-parent={DataId.BottomAdhesionAd}
          onClick={() => setShowAd(false)}
          ripple={false}
          rounded
          size="small"
        >
          <Icon path={mdiClose} size="100%" />
        </IconButton>
      )}
      <Ad
        additionalAdPath="bottomadhesion"
        className="flex items-center justify-center"
        onAdRendered={handleAdRender}
        pageTargeting={pageTargeting}
        sizes={sizes}
        targeting={adTargeting}
      />
    </div>
  );
};

export default BottomAdhesionAd;
