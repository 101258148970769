import { Routes } from '@/constants/routes';

export const getInterstitialAdPath = (
  adPath: string,
  currentPath: string | null,
  targeting: { [key: string]: string | string[] },
) => {
  const bodyStyleOnly =
    Object.keys(targeting).includes('bodystyleonly') || false;

  return currentPath === Routes.BuyersGuideHomepage ||
    bodyStyleOnly ||
    currentPath?.includes(Routes.Price)
    ? `${adPath}/null/interstitial`
    : `${adPath}/interstitial`;
};
