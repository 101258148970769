'use client';

import { useEffect, useRef, type FunctionComponent } from 'react';
import { usePathname } from 'next/navigation';

import { type AdConfig } from '@/types/AdConfig';

import { getInterstitialAdPath } from '@/utils/ads/getInterstitialAdPath';
import gptEnableServices from '@/utils/ads/googlePublisherTag/gptEnableServices';
import initGoogletag from '@/utils/ads/googlePublisherTag/initGoogletag';
import { adLog } from '@/utils/grafana/adsTrace';

const InterstitialAd: FunctionComponent<{ pageTargeting: AdConfig }> = ({
  pageTargeting,
}) => {
  const { adPath, adTargeting } = pageTargeting;
  const adInitializedRef = useRef(false);
  const currentPath = usePathname();

  // Check if adTargeting is valid
  const isValidAdTargeting = adTargeting && Object.keys(adTargeting).length > 0;

  useEffect(() => {
    if (!adPath || !isValidAdTargeting || adInitializedRef.current) {
      return;
    }

    // Mark ad as initialized
    adInitializedRef.current = true;

    // Build the interstitial ad path and initialize Googletag
    const finalTargeting = {
      ...adTargeting,
      pos: ['interstitial'],
      refresh: 'false',
    };
    const interstitialAdPath = getInterstitialAdPath(
      adPath,
      currentPath,
      finalTargeting,
    );

    initGoogletag();

    let interstitialSlot: googletag.Slot | null;

    window.googletag.cmd.push(() => {
      adLog('AdDisplayRequest', { slotId: interstitialAdPath });

      // Define the interstitial slot
      interstitialSlot = googletag.defineOutOfPageSlot(
        interstitialAdPath,
        googletag.enums.OutOfPageFormat.INTERSTITIAL,
      );

      if (interstitialSlot) {
        interstitialSlot.addService(googletag.pubads());

        // Set targeting in a single line for better readability
        Object.entries(finalTargeting).forEach(([key, value]) =>
          interstitialSlot?.setTargeting(key, value),
        );

        // Enable services once for the slot, if necessary
        gptEnableServices();

        adLog('AdDisplayRequest', {
          ...finalTargeting,
          slotId: interstitialAdPath,
        });
        googletag.display(interstitialSlot);
      }
    });

    // Clean up the interstitial slot when component unmounts
    return () => {
      if (interstitialSlot) {
        window.googletag.cmd.push(() => {
          googletag.destroySlots([interstitialSlot as googletag.Slot]);
        });
      }
    };
  }, [adPath, adTargeting, currentPath, isValidAdTargeting]);

  return null;
};

export default InterstitialAd;
