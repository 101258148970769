import { DtcWidgetStrings as strings } from '@/translations';
import { mdiCounter, mdiMapMarkerOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Card, CardContent, CardMedia, Typography } from '@motortrend/ids';

import convertToCurrency from '@/utils/convertToCurrency';
import { MOTORTREND_MARKETPLACE_URL } from '@/utils/dtcWidget/constants';
import formatNumberWithCommas from '@/utils/formatNumberWithCommas';
import { DataId } from '@/utils/nitrous/constants';
import { normalizeUrl } from '@/utils/normalizeUrl';
import replacePlaceholders from '@/utils/replacePlaceholders';
import { NextLink } from '@/components/NextLink';

import {
  type DealerNameProps,
  type DtcWidgetCardProps,
  type MilesProps,
  type PriceProps,
  type YearMakeModelProps,
} from './DtcWidgetCard.props';

const YearMakeModel = ({ text }: YearMakeModelProps) => (
  <Typography
    as="p"
    className="-mt-1 pb-2 !text-sm text-secondary-1 dark:text-secondary-2"
    disableColorScheme
    variant="subtitle2"
  >
    {text}
  </Typography>
);

const Price = ({ text }: PriceProps) => {
  if (!text) {
    return null;
  }

  return (
    <div>
      <Typography as="span" variant="h6">
        {text}
      </Typography>
      <Typography
        as="span"
        className="hidden pl-1 font-semibold md:inline"
        variant="caption2"
      >
        {strings.MSRP}
      </Typography>
    </div>
  );
};

const Miles = ({ text }: MilesProps) => {
  if (!text) {
    return null;
  }

  return (
    <Typography className="max-w-xs" noWrap variant="caption2">
      <Icon className="mr-1 inline" path={mdiCounter} size={0.75} />
      {text}
    </Typography>
  );
};

const DealerName = ({ text }: DealerNameProps) => {
  if (!text) {
    return null;
  }

  return (
    <Typography className="max-w-xs" noWrap variant="caption2">
      <Icon className="mr-1 inline" path={mdiMapMarkerOutline} size={0.75} />
      {text}
    </Typography>
  );
};

const DtcWidgetCard = ({ dtcListing, zipCode }: DtcWidgetCardProps) => {
  const {
    VIN,
    dealerName,
    imageUrl,
    make,
    mileage,
    model,
    sellingPrice,
    year,
  } = dtcListing;

  const inventoryLinkUrl = normalizeUrl({
    base: MOTORTREND_MARKETPLACE_URL,
    paths: ['vehicle-detail'],
    queryParams: { vin: VIN, zip: zipCode },
  });

  const price = convertToCurrency(sellingPrice) || '';
  const yearMakeModelText = `${year} ${make} ${model}`;
  const miles = formatNumberWithCommas(mileage) || '';
  const milesText = miles
    ? replacePlaceholders(strings.Miles, {
        mileage: miles,
      })
    : '';

  return (
    <Card
      as={NextLink}
      className="rounded-lg bg-neutral-7 shadow-lg transition-colors dark:bg-neutral-2 dark:shadow-none"
      data-id={DataId.DtcWidgetCard}
      data-nitrous-car_data_array={JSON.stringify([dtcListing || {}])}
      data-nitrous-car_make={make}
      data-nitrous-car_model={model}
      data-nitrous-car_year={year}
      data-parent={DataId.DtcWidget}
      href={inventoryLinkUrl}
      orientation="vertical"
      target="_blank"
    >
      <CardMedia
        alt={yearMakeModelText}
        as="img"
        aspectRatio="16/9"
        src={imageUrl}
        variant="rounded-top"
      />
      <CardContent className="p-2 pt-0">
        <Price text={price} />
        <YearMakeModel text={yearMakeModelText} />
        <hr className="border-secondary-1 md:my-1 dark:border-secondary-2" />
        <div className="md:my-1">
          <Miles text={milesText} />
          <DealerName text={dealerName} />
        </div>
      </CardContent>
    </Card>
  );
};

export default DtcWidgetCard;
