import Image from 'next/image';
import { DtcWidgetStrings as strings } from '@/translations';
import { Link } from '@motortrend/ids';

import { MOTORTREND_MARKETPLACE_URL } from '@/utils/dtcWidget/constants';
import { DataId } from '@/utils/nitrous/constants';
import { normalizeUrl } from '@/utils/normalizeUrl';

import marketplaceBannerDesktop from '@/assets/images/marketplace-banner-desktop.png';
import marketplaceBannerMobile from '@/assets/images/marketplace-banner-mobile.png';
import marketplaceBannerTablet from '@/assets/images/marketplace-banner-tablet.png';

import { type DtcMarketplaceBannerProps } from './DtcWidgetMarketplaceBanner.props';

const DtcMarketplaceBanner = ({ zipCode }: DtcMarketplaceBannerProps) => {
  const href = zipCode
    ? normalizeUrl({
        base: MOTORTREND_MARKETPLACE_URL,
        paths: ['search-results'],
        queryParams: { zip: zipCode },
      })
    : normalizeUrl({ base: MOTORTREND_MARKETPLACE_URL, paths: ['/'] });

  return (
    <Link
      className="flex justify-center"
      data-id={DataId.DtcWidgetMarketplaceBanner}
      data-parent={DataId.ArticleBody}
      href={href}
      target="_blank"
    >
      <Image
        alt={strings.MarketplaceBannerAltText}
        className="hidden h-auto w-full lg:block"
        height={90}
        src={marketplaceBannerDesktop}
        width={970}
      />
      <Image
        alt={strings.MarketplaceBannerAltText}
        className="hidden h-auto w-full md:block lg:hidden"
        height={90}
        src={marketplaceBannerTablet}
        width={728}
      />
      <Image
        alt={strings.MarketplaceBannerAltText}
        className="h-auto w-full md:hidden"
        height={300}
        src={marketplaceBannerMobile}
        width={250}
      />
    </Link>
  );
};

export default DtcMarketplaceBanner;
