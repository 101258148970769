import { DtcWidgetStrings as strings } from '@/translations';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@motortrend/ids';

import { pageNames } from '@/utils/dtcWidget/constants';
import { generateHeaderText } from '@/utils/dtcWidget/generateHeaderText';
import { DataId } from '@/utils/nitrous/constants';

import DtcWidgetCard from '../DtcWidgetCard';
import DtcWidgetHeader from '../DtcWidgetHeader';
import DtcWidgetYMMHeader from '../DtcWidgetYMMHeader';
import { type DtcWidgetListingsProps } from './DtcWidgetListings.props';

const DtcWidgetListings = ({
  currentPage,
  dtcListings,
  make,
  model,
  zipCode,
}: DtcWidgetListingsProps) => {
  const headerText = generateHeaderText(make, model);

  const renderDtcWidgetHeader = () => {
    switch (currentPage) {
      case pageNames['make']:
      case pageNames['makeStyle']:
      case pageNames['mmy']:
      case pageNames['bghp']:
        return <DtcWidgetYMMHeader text={strings.YmmSpecificHeaderText} />;
      default:
        return <DtcWidgetHeader text={headerText} />;
    }
  };

  return (
    <div className="px-4" data-id={DataId.DtcWidgetListings}>
      {renderDtcWidgetHeader()}
      <Carousel>
        <CarouselContent>
          {dtcListings.map((dtcListing, index) => (
            <CarouselItem
              className="mb-4 !flex-[0_0_14rem] md:!flex-[0_0_15rem]"
              key={`CarouselItem-${index}`}
            >
              <DtcWidgetCard
                dtcListing={dtcListing}
                index={index}
                zipCode={zipCode}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default DtcWidgetListings;
