const convertToCurrency = (amt: string = '0'): string | null => {
  const numericAmt = parseFloat(amt);

  if (isNaN(numericAmt)) {
    return null;
  }

  const formattedCurrency: string = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 0,
    style: 'currency',
  }).format(numericAmt);

  return formattedCurrency;
};

export default convertToCurrency;
