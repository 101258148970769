'use client';

import { useEffect, useMemo, type FunctionComponent } from 'react';
import useStore from '@/store';
import { TrackImpression } from '@motortrend/nitrous-web-sdk';
import { useQuery } from '@tanstack/react-query';
import cx from 'clsx';

import { type DtcListing } from '@/types/DtcListings';

import { MINIMUM_DTC_LISTING_COUNT } from '@/utils/dtcWidget/constants';
import vehicleDisplayNameAction from '@/actions/vehicleDisplayNameAction';

import { adConfigSelector } from '@/store/selectors';

import { type DtcWidgetProps } from './DtcWidget.props';
import DtcWidgetListings from './DtcWidgetListings';
import DtcMarketplaceBanner from './DtcWidgetMarketplaceBanner';

const DtcWidget: FunctionComponent<DtcWidgetProps> = ({
  className,
  currentPage = '',
  dtcListingsPayload,
}) => {
  const { setZipCode: setZipCodeFromStore } = useStore();
  const { adTargeting } = useStore(adConfigSelector);

  const payload = useMemo(() => {
    const hasProvidedProps =
      dtcListingsPayload && Object.values(dtcListingsPayload).some(Boolean);
    return hasProvidedProps
      ? dtcListingsPayload
      : {
          // make and model from adTargeting is in slug format
          makeSlug: adTargeting.make,
          modelSlug: adTargeting.model,
        };
  }, [dtcListingsPayload, adTargeting.make, adTargeting.model]);

  const { data: dtcData } = useQuery({
    enabled: Object.values(payload).some(Boolean),
    queryFn: async () => {
      const response = await fetch('/nwapi/dtc/', {
        body: JSON.stringify(payload),
        method: 'POST',
      });
      if (!response.ok) {
        throw new Error(`fetchDtcListings ${response.status}`);
      }
      return response.json();
    },
    queryKey: ['dtcListings', payload],
  });

  const needsDisplayNames = !payload.make || !payload.model;

  // TODO: Use make and model display names from article data when available from api (CD-1536)
  const { data: displayNameData } = useQuery({
    enabled:
      needsDisplayNames && Boolean(payload.makeSlug && payload.modelSlug),
    queryFn: () =>
      vehicleDisplayNameAction({
        makeSlug: payload.makeSlug,
        modelSlug: payload.modelSlug,
      }),
    queryKey: ['vehicleDisplayName', payload.makeSlug, payload.modelSlug],
  });

  const dtcListings: DtcListing[] = dtcData?.dtcListings ?? [];
  const zipCode: string = dtcData?.zipCode ?? '';

  const makeDisplayName =
    payload.make || displayNameData?.vehicle?.make?.name || '';
  const modelDisplayName =
    payload.model || displayNameData?.vehicle?.model?.name || '';

  // TODO: Set zip code in store somewhere else, ex: SeeListingsButton should not rely on this component
  useEffect(() => {
    if (zipCode) {
      setZipCodeFromStore({ zipCode });
    }
  }, [zipCode, setZipCodeFromStore]);

  const showDtcListings = dtcListings.length >= MINIMUM_DTC_LISTING_COUNT;

  return (
    <div
      className={cx(
        'col-span-4 rounded-lg bg-neutral-8 transition-colors md:col-span-5 xl:col-span-8 dark:bg-neutral-1',
        { 'shadow-lg': showDtcListings },
        className,
      )}
    >
      {showDtcListings ? (
        <TrackImpression
          data-nitrous-content-impression-id="inventory_widget"
          data-nitrous-content-impression-variant="listings"
        >
          <DtcWidgetListings
            currentPage={currentPage}
            dtcListings={dtcListings}
            make={makeDisplayName}
            model={modelDisplayName}
            zipCode={zipCode}
          />
        </TrackImpression>
      ) : (
        <TrackImpression
          data-nitrous-content-impression-id="inventory_widget"
          data-nitrous-content-impression-variant="fallback_banner"
        >
          <DtcMarketplaceBanner zipCode={zipCode} />
        </TrackImpression>
      )}
    </div>
  );
};

export default DtcWidget;
