'use client';

import { useEffect, useState, type FunctionComponent } from 'react';
import { BrightEdgeStrings as strings } from '@/translations';
import {
  Card,
  CardContent,
  CardGrid,
  CardTitle,
  Typography,
} from '@motortrend/ids';
import { withErrorBoundary } from 'react-error-boundary';
import {
  getBrightEdgeData,
  getContentByType,
  type RelatedLinks,
} from 'utils/getBrightEdgeLinks';

import { DataId } from '@/utils/nitrous/constants';
import { normalizeUrl } from '@/utils/normalizeUrl';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

import { type BrightEdgeProps } from './BrightEdge.props';

const BrightEdge: FunctionComponent<BrightEdgeProps> = ({ className }) => {
  const [relatedLinks, setRelatedLinks] = useState<RelatedLinks>();
  const [brightEdgeHeader, setBrightEdgeHeader] = useState<string>('');
  useEffect(() => {
    try {
      getBrightEdgeData(window.location.href).then((data) => {
        if (data) {
          const bodyString = getContentByType(data, 'bodystr')?.content || '';
          const relatedLinks: RelatedLinks =
            bodyString && JSON.parse(bodyString);
          setRelatedLinks(relatedLinks);
          setBrightEdgeHeader(getContentByType(data, 'headstr')?.content || '');
        }
      });
    } catch (e) {
      console.error('Error in BrightEdge:', e);
    }
  }, []);

  const getLinks = () => {
    return relatedLinks?.links?.map((item, index) => (
      <Card
        as="a"
        data-id={DataId.BrightEdgeLink}
        data-parent={DataId.BrightEdgeSection}
        href={normalizeUrl({ paths: [item.url] })}
        key={`${item.url}_${index}`}
        variant="tile"
      >
        <CardContent>
          <CardTitle
            title={item.anchor_text}
            titleTypographyProps={{ as: 'h3' }}
          />
        </CardContent>
      </Card>
    ));
  };

  return (
    relatedLinks?.links?.length && (
      <div className={className}>
        <Typography as="h2" md="h4" variant="h5">
          {strings.Heading}
        </Typography>
        <CardGrid className="pt-4 sm:pt-6" columns={2} lg={3}>
          {getLinks()}
        </CardGrid>
        <div dangerouslySetInnerHTML={{ __html: brightEdgeHeader }} />
      </div>
    )
  );
};

export default withErrorBoundary(BrightEdge, {
  FallbackComponent: NoUiErrorFallback,
});
