import { DtcWidgetStrings as strings } from '@/translations';

import titleCase from '@/utils/titleCase';

import replacePlaceholders from '../replacePlaceholders';

export const generateHeaderText = (make?: string, model?: string) => {
  if (make && model) {
    return replacePlaceholders(strings.HeaderText, {
      makeAndModel: titleCase(`${make} ${model}`),
    });
  }

  return strings.FallbackHeaderText;
};
