import { Typography } from '@motortrend/ids';

import { MOTORTREND_MARKETPLACE_URL } from '@/utils/dtcWidget/constants';
import { DataId } from '@/utils/nitrous/constants';
import { NextLink } from '@/components/NextLink';

import CarMagnifierLogo from '@/assets/logos/car-magnifier.svg';
import MarketplaceLogo from '@/assets/logos/marketplace.svg';

import MotortrendLogo from '../../../node_modules/@motortrend/ids/lib/assets/images/motortrend.svg';
import { type DtcWidgetHeaderProps } from './DtcWidgetHeader.props';

const DtcWidgetHeaderLogo = () => (
  <NextLink
    className="mt-2 flex h-11 w-full md:my-2 md:ml-2 md:h-14 md:w-40 md:items-end"
    data-id={DataId.DtcWidgetHeaderLogo}
    data-parent={DataId.DtcWidgetListings}
    href={MOTORTREND_MARKETPLACE_URL}
    target="_blank"
  >
    <div className="mb-1 flex flex-grow flex-col justify-center gap-1">
      <div className="flex flex-grow items-end">
        <MotortrendLogo className="h-3 w-min text-secondary-1 md:h-3" />
        <hr className="flex-grow border-secondary-1" />
      </div>
      <MarketplaceLogo className="h-3 w-min md:h-3" />
    </div>
    <CarMagnifierLogo className="-mr-2 h-11 w-11 md:h-14 md:w-14" />
  </NextLink>
);

const DtcWidgetHeader = ({ text }: DtcWidgetHeaderProps) => (
  <>
    <div className="h-3 rounded-t bg-secondary-1 drop-shadow" />
    <div className="mx-4 flex flex-col-reverse items-start justify-between md:flex-row md:items-end">
      <Typography as="p" className="mb-1" md="h4" variant="h5">
        {text}
      </Typography>
      <DtcWidgetHeaderLogo />
    </div>
  </>
);

export default DtcWidgetHeader;
